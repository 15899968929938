import allCountries from "../../countries.json"
import { store } from "../vuex/store"

const getPhoneIntlCodes = (params) => {
	var list = []

	if(params && params.showAllCountries){
		for (const key in allCountries) {
			list.push({
				name: allCountries[key].name,
				iso2: key.toLowerCase(),
				dialCode: allCountries[key].dial_code.replace("+", ""),
			})
		}

		return list
	}
	
	store.state.settings.enabledCountries.forEach((key) => {
		list.push({
			name: allCountries[key].name,
			iso2: key.toLowerCase(),
			dialCode: allCountries[key].dial_code.replace("+", ""),
		})
	})

	return list
}

export default getPhoneIntlCodes
