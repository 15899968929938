import { createStore } from 'vuex'
import { setLocale as setDayJsLocale } from '../includes/dayjs'

const state = {
	offline: false,
	authorized: null,
	facebookLoaded: false,
	facebookUserId: "me",
	loggedIn: null,
	userInitialized: false,
	userLoaded: false,
	userPoints: false,
	userTotalPoints: false,
	userFullName: '',
	userFirstName: '',
	userLastName: '',
	userPhone: '',
	userEmail: '',
	userHash: '',
	userImage: '',
	settings: {
		languagesSupported: [],
	},
	menuItems: false,
	user: {
		monthlyTopUrl: "",
		isInMonthlyTop: false,
		birthday: null,
	},
	applyReferralCodeEnabled: null,
	applyPromoCodeEnabled: null,
	isMobile: false,
	friends: false,
	friendsIndex: false,
	referralId: '',
	news: {
		unreadCount: 0,
		promotedNewsItem: false
	},
	prizes: {
		maxPrizeValue: null,
		favourites: null,
		favouriteIds: [],
	},
	notificationSettings: false,
	connectionStatus: {
		google: "",
		facebook: "",
		linkedin: "",
	},
	genericPopupOpened: false,
	owner: {
		id: 0,
		image: false,
	},
	smsInitiator: false,
	rewards: false,
	currentLanguage: false,


	isPushSetUp: false,
	isPushDenied: false,
	isPushNotDenied: false,
	isPushGranted: false,
}

const mutations = {
	// A mutation receives the current state as the first argument
	// You can make any modifications you want inside this function
	setOffline(state, value){
		state.offline = value
	},

	setAuthorized(state, value){
		state.authorized = value
	},

	setFacebookLoaded(state, value){
		state.facebookLoaded = value
	},

	setFacebookUserId(state, value){
		state.facebookUserId = value
	},

	setUserPoints(state, value){
		state.userPoints = value
	},

	setUserTotalPoints(state, value){
		state.userTotalPoints = value

		// Update points in leaderboard
		if(state.friendsIndex && state.facebookUserId && state.friendsIndex[state.facebookUserId + ""]){
			state.friendsIndex[state.facebookUserId + ""].points = value

			// Sort list by points
			state.friends.sort((a, b) => {
				return b.points - a.points
			})
		}
	},

	setLoggedIn(state, value){
		state.loggedIn = value

		// User is logging off so we should clean all the data
		if(value == false){
			state.userInitialized = false
			state.userLoaded = false
			state.userPoints = false
			state.userTotalPoints = false
			state.userFullName = ''
			state.userFirstName = ''
			state.userLastName = ''
			state.userPhone = ''
			state.userImage = ''
			state.user.monthlyTopUrl = ""
			state.user.isInMonthlyTop = false
			state.friends = false
			state.friendsIndex = false
			state.referralId = ""
			state.rewards = false
		} 
	},

	setUserFirstName(state, value){
		state.userFirstName = value
		state.userFullName = value + " " + state.userLastName
	},

	setUserLastName(state, value){
		state.userLastName = value
		state.userFullName = state.userFirstName + " " + value
	},

	setUserPhone(state, value){
		state.userPhone = value
	},
	
	setUserEmail(state, value){
		state.userEmail = value
	},
	
	setUserHash(state, value){
		state.userHash = value
	},

	setUserBirthday(state, value){
		state.user.birthday = value
	},

	setApplyReferralCodeEnabled(state, value){
		state.applyReferralCodeEnabled = value
	},

	setApplyPromoCodeEnabled(state, value){
		state.applyPromoCodeEnabled = value
	},

	setIsInMonthlyTop(state, value){
		state.user.isInMonthlyTop = value
	},
	
	setMonthlyTopUrl(state, value){
		state.user.monthlyTopUrl = value
	},

	setSettings(state, value){
		state.settings = value
	},

	setMobile(state, value){
		state.isMobile = value
	},

	setUnreadNewsCount(state, value){
		state.news.unreadCount = value
	},

	setPromotedNewsItem(state, value){
		state.news.promotedNewsItem = value
	},

	setFriends(state, value){
		state.friends = value
	},

	setFriendsIndex(state, value){
		state.friendsIndex = value
	},

	setReferralId(state, value){
		state.referralId = value
	},

	setUserPicture(state, value){
		if(state.friendsIndex && state.friendsIndex[state.facebookUserId + ""] && !state.friendsIndex[state.facebookUserId + ""].picture_url){
			state.friendsIndex[state.facebookUserId + ""].picture_url = value
		}

		state.userImage = value
	},

	setNotificationSettings(state, value){
		state.notificationSettings = value
	},

	setConnectionStatuses(state, value){
		state.connectionStatus = value
	},

	setConnectionStatus(state, value){
		state.connectionStatus[value.provider] = value.status
	},

	setGenericPopupOpened(state, value){
		state.genericPopupOpened = value
	},

	setOwner(state, value){
		state.owner = value
	},

	setSmsInitiator(state, value){
		state.smsInitiator = value
	},

	setUserInitialized(state, value){
		state.userInitialized = value
	},

	setUserDataLoaded(state, value){
		state.userLoaded = value
	},

	setRewards(state, value){
		state.rewards = value
	},

	setCurrentLanguage(state, value){
		state.currentLanguage = value

		// Set dayjs locale on language change
		setDayJsLocale(value)
	},

	setMenuItems(state, value){
		state.menuItems = value
	},

	setMaxPrizeValue(state, value){
		state.prizes.maxPrizeValue = value
	},

	setPrizesFavourites(state, value){
		state.prizes.favourites = value

		// Let's also have favorite prize id's so we could quickly check if any prize is a favorite without a server call
		state.prizes.favouriteIds = value.map(item => item.id)
	},

	resetPrizesFavourites(state){
		state.prizes.favourites = null
	},

	isPushSetUp(state, value){ state.isPushSetUp = value },
	isPushDenied(state, value){ state.isPushDenied = value },
	isPushNotDenied(state, value){ state.isPushNotDenied = value },
	isPushGranted(state, value){ state.isPushGranted = value },
}

// Combine the initial state and the mutations to create a Vuex store.
// This store can be linked to our app.
// export default new Vuex.Store({
export const store = createStore({
	state,
	mutations,
})
